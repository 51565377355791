import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import hosGeldin from "../images/hosgeldin.svg"
import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"

const InvitePage = ({ location }) => {


return (
<Layout>
<Head title="Hadi Çember'e Katıl" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Bu sayfadan Çember'i indirebilirsin." />
<div className="row invitation">
    <div className="hero-container">
    <div className="container">
    <div className="col-6">
    
        <h1>Çember'e Hoş Geldin</h1>    
       
        <p className="summary" >Çember, <b>altın günü</b> modeli kullanarak hayallerin için 
              arkadaşlarınla para biriktirebileceğin bir uygulamadır. Buradan üye olduktan sonra uygulamayı indirip hemen giriş yapabilirsin. </p>
              <p>Hadi hemen gel, arkadaşların seni bekliyor. </p>
        <p>İndirmek için:</p>
        <div className="downloadbuttons">
            <a href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
            <a href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
        </div>
        
    </div>
    <div className="col-6">
    <img src={hosGeldin} alt="Hoş Geldin" />

    </div>
    </div>
</div>
</div>
    </Layout>

    
)
    
    
}

export default InvitePage